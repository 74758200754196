import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { useNavigate } from "react-router-dom";
import { sendPointsToServer, rateProductWithClaimedPoints, getAllTracks, getAllCategories } from '../../logic/server/api';

import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import WelcomeImage from '../../assets/backgrounds/leaderboard-bg.jpeg';
import LeaderBoardRow from './LeaderBoardRow';
import LeaderBoardGrid from './LeaderBoardGrid';
import { ReactComponent as ChevronSort } from '../../assets/icons/chevron-sort.svg';
import { PATH_CREATE } from '../../constants/Paths';

const LeaderBoard = () => {
    const navigate = useNavigate();
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [view, setView] = useState('list');
    const audioRef = useRef(null);
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [activeFilter, setActiveFilter] = useState("everything");
    const [startTime, setStartTime] = useState(null);

    useEffect(() => {
        fetchCategories();
        fetchTracks();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getAllCategories();
            if (response && response.status) {
                const updatedCategories = response.categories.map((category, index) => {
                    if (index === 0) {
                        return { ...category, title: 'Everything', name: 'everything' };
                    }
                    return category;
                });
                setCategories(updatedCategories);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }

    const fetchTracks = async (categoryId = null) => {
        setLoading(true);
        try {
            const id = localStorage.getItem('customer');
            const token = localStorage.getItem('token');
            if (!id || !token) {
                throw new Error('Merchant ID or token not found in localStorage');
            }

            const response = await getAllTracks(id, token, categoryId);

            if (response && response.status) {
                setTracks(response.data);
            }
        } catch (error) {
            console.error('Error fetching tracks:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    const handleVote = () => {
        console.log('Vote clicked');
    };

    const handlePlayPause = async (track) => {
        if (currentTrack && currentTrack.url === track.url) {
            if (isPlaying) {
                if (audioRef.current) {
                    audioRef.current.pause();
                }
            } else {
                if (audioRef.current) {
                    audioRef.current.play();
                    setStartTime(Date.now());
                }
            }
            setIsPlaying(!isPlaying);
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            const previousTrack = currentTrack;
            setCurrentTrack(track);
            setIsPlaying(true);
            let response;
            if (previousTrack) {
                const playedSeconds = Math.floor((Date.now() - startTime) / 1000);
                const reward = previousTrack.reward * playedSeconds;
                if (reward > 0) {
                    await rateProductWithClaimedPoints(reward, previousTrack.id);
                    response = await sendPointsToServer(reward);
                }
            }
            if (response && response.status) {
                setProgress(0);
            }
            audioRef.current.src = track.url;
            audioRef.current.play();
            setStartTime(Date.now());
        }
    };

    const handleProgress = (progress) => {
        setProgress(progress);
    };

    const handleTrackEnd = async () => {
        if (currentTrack) {
            const playedSeconds = Math.floor((Date.now() - startTime) / 1000);
            const reward = currentTrack.reward * playedSeconds;
            if (reward > 0) {
                await rateProductWithClaimedPoints(reward, currentTrack.id);
                await sendPointsToServer(reward);
            }
        }

        const currentIndex = tracks.findIndex(track => track.url === currentTrack.url);
        const nextIndex = (currentIndex + 1) % tracks.length;
        const nextTrack = tracks[nextIndex];

        setCurrentTrack(nextTrack);
        setIsPlaying(true);
        setStartTime(Date.now());
        setProgress(0);
        audioRef.current.src = nextTrack.url;
        audioRef.current.play();
    };

    const toggleView = () => {
        setView(view === 'list' ? 'grid' : 'list');
    };

    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
        const selectedCategory = categories.find(category => category.name === filter.toLowerCase());
        fetchTracks(selectedCategory ? selectedCategory.category_id : null);
    };

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                setProgress(progress);
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
            audioRef.current.addEventListener('ended', handleTrackEnd);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                audioRef.current.removeEventListener('ended', handleTrackEnd);
            }
        };
    }, [currentTrack]);

    return (
        <TelegramScreen className={``} id='gesuredZone'>
            <div className='main-container'>
                <div className='welcome-holder'>
                    <div className="columns-holder">
                        <div className="column">
                            <div className="welcome">
                                <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>Top Chart</span></TelegramText>
                                <TelegramText className={'white-opacity'}>All generated music shows up here. More votes it receive – higher place it takes. You can vote for one track as many times, as you want</TelegramText>
                                <TelegramButton className={'block'} onClick={() => handleNavigate(PATH_CREATE)}>Create a new track</TelegramButton>
                                <div className="decor">
                                    <img src={WelcomeImage} alt={'Welcome'} />
                                </div>
                                {currentTrack && (
                                    <div className="current-track-player">
                                        <LeaderBoardRow
                                            slug={currentTrack.slug}
                                            trackUrl={currentTrack.url}
                                            title={currentTrack.name}
                                            reward={currentTrack.reward}
                                            votes={currentTrack.votes}
                                            onVote={handleVote}
                                            onPlayPause={() => handlePlayPause(currentTrack)}
                                            isPlaying={isPlaying}
                                            onProgress={handleProgress}
                                            volume={0}
                                            isPlayer={true}
                                            index={tracks.findIndex(track => track.url === currentTrack.url)}
                                            logoImage={currentTrack.image}
                                            logoText={currentTrack.logoText}
                                        />
                                        <div className="progress-bar">
                                            <div className="progress" style={{ width: `${progress}%` }}></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="column">
                            <div className="leaderboard">
                                {categories?.length > 0 ? <div className="leaderboard-filters">
                                    {categories.map((category, index) => (
                                        <button
                                            key={category.category_id}
                                            className={`filter-button ${activeFilter === category.name ? "active" : ""}`}
                                            onClick={() => handleFilterChange(category.name)}
                                        >
                                            {category.title}
                                        </button>
                                    ))}
                                </div> : null}
                                {loading ? (
                                    <div className="loading">
                                        <TelegramText className={'white-opacity'}>Loading...</TelegramText>
                                    </div>
                                ) : tracks.length === 0 ? (
                                    <div className="no-results">
                                        <TelegramText className={'white-opacity'}>There are no results, try another request or <Link to={PATH_CREATE} className="link">create a new track</Link>.</TelegramText>
                                    </div>
                                ) : (
                                    <>
                                        <button onClick={toggleView} className="toggle-view-button">
                                            <ChevronSort />
                                            {view === 'list' ? 'Switch to Grid view' : 'Switch to List view'}
                                        </button>
                                        {view === 'list' ? (
                                            <div className="leaderboard-list">
                                                {tracks.map((track, index) => (
                                                    <LeaderBoardRow
                                                        key={index}
                                                        slug={track.slug}
                                                        trackUrl={track.url}
                                                        title={track.name}
                                                        reward={track.reward}
                                                        plays={track.plays}
                                                        votes={track.votes}
                                                        onVote={handleVote}
                                                        onPlayPause={() => handlePlayPause(track)}
                                                        isPlaying={currentTrack && currentTrack.url === track.url && isPlaying}
                                                        onProgress={currentTrack && currentTrack.url === track.url ? handleProgress : null}
                                                        volume={currentTrack && currentTrack.url === track.url ? 1 : 0}
                                                        index={index}
                                                        logoImage={track.image}
                                                        logoText={track.logo_text}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <LeaderBoardGrid tracks={tracks} handlePlayPause={handlePlayPause} />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <MainNavigation currentPage={'leaderboard'} />
            </div>
            <audio ref={audioRef} />
        </TelegramScreen>
    );
};

export default LeaderBoard;