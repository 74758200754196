import React, { useEffect, useMemo, useRef, useState } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";

import { useDispatch, useSelector } from "react-redux";
import { incrementByAmount } from "../main/counterSlice";
import {
    animated, BailSignal,
    useChain, useSpring,
    useSpringRef,
    useTransition
} from '@react-spring/web';
import Splash from "../splash/splash";
import { openApp } from "../main/settingsSlice";
import { DropI, PickleballClass, PickleballI } from "../main/pickleballLogic";

import {
    changeCustomerData,
    getGameReward,
    getNextBall
} from "../../logic/server/api";
import { ACTIVE_TIME, SlEEP_TIME } from "../../logic/server/Variables";

/* redesign imports */
import MainNavigation from "../../components/app/functions/MainNavigation";
import TelegramButton from '../../components/kit/Button/TelegramButton';
import TelegramText from '../../components/kit/Text/TelegramText';
import CryptoInfo from '../../components/kit/CryptoInfo/CryptoInfo';
import WelcomeImage from '../../assets/welcome-image1.png';
import ClipboardButton from '../../components/kit/ClipboardButton/ClipboardButton';

import BaseIcon from '../../assets/icons/icon-base.svg';
import BitcoinIcon from '../../assets/icons/icon-bitcoin.svg';
import SolanaIcon from '../../assets/icons/icon-solana.svg';
import EthereumIcon from '../../assets/icons/icon-ethereum.svg';
import PolygonIcon from '../../assets/icons/icon-polygon.svg';

interface AnimatedStyle {
    x: number;
    y: number;
    opacity: number;
}

const AboutPage = React.memo(({ collectibles, person, audioArray, winningAudio }: any) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    // custom cursor
    const [MousePosition, setMousePosition] = useState({
        left: -250,
        top: -250
    });
    const [reward, setReward] = useState({ image: '', asset: '', rarity: '', id: '', name: '', price: 0 });
    const [ballType, set] = useState<PickleballI[] | []>([]);
    const [nextBall, setNextBall] = useState<PickleballI | null>(null);

    const [isClicked, setIsClicked] = useState(false);
    const [points, setPoints] = useState(0);
    const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
    const [isGameStarted, setGameStarted] = useState(false);
    const intervalRef = useRef<number | null>(null);
    // timer 5 minutes
    const [time, setTime] = useState<number>();
    const [sleepTime, setSleepTime] = useState<number>();
    const [timeOut, setTimeOutState] = useState<boolean>(localStorage.getItem('timeOut') ?
        JSON.parse(localStorage.getItem('timeOut') || '') : false);
    const [currentAudio, setCurrentAudio] = useState(getRandomElement(audioArray));


    useEffect(() => {
        if (person?.profile) {
            const timeActive = !!person.profile.timeout ? '' : ACTIVE_TIME;
            const timeSleep = !!person.profile.timeout ? SlEEP_TIME : '';
            setTime(!!person.profile.time && !person.profile.timeout ? person.profile.time : timeActive);
            setSleepTime(!!person.profile.sleeptime ? person.profile.sleeptime : timeSleep);
            setTimeOutState(!!person.profile.timeout || false);

        }
    }, [person]);
    // console.log(person);

    const cancelMap = useMemo(() => new WeakMap(), []);
    // on animation start
    const nextMap = useMemo(() => new WeakMap(), []);

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // active time timer
    useEffect(() => {
        let interval: any;
        if (!timeOut && time && time > 0 && isGameStarted && person) {
            interval = setInterval(() => {
                localStorage.setItem('time', (time - 10).toString());
                setTime((prevTimer1) => prevTimer1 && (prevTimer1 - 10));
            }, 10);
            if (time % 1000 === 0) {
                changeCustomer(time, 0, false);
            }
        } else if (time === 0 && !timeOut && person) {
            clearInterval(interval);
            setTimeOutState(true); // Switch to Timer 2 when Timer 1 finishes
            localStorage.setItem('time', '');
            localStorage.setItem('timeOut', JSON.stringify(true));
            setSleepTime(SlEEP_TIME); // Reset Timer 2
            changeCustomer(0, SlEEP_TIME, true)
        }
        return () => clearInterval(interval);

    }, [time, timeOut, isGameStarted]);

    async function changeCustomer(time: number, sleepTime: number, timeOut: boolean) {
        if (person?.profile) {
            const editedPerson: any = JSON.parse(JSON.stringify(person));
            editedPerson.profile.time = time;
            editedPerson.profile.sleeptime = sleepTime;
            editedPerson.profile.timeout = timeOut;
            const resp = await changeCustomerData(editedPerson, editedPerson.customer_id);
        }
    }

    // sleep time timer
    useEffect(() => {
        let interval: any;
        if (timeOut && sleepTime && sleepTime > 0 && person) {
            interval = setInterval(() => {
                localStorage.setItem('sleepTime', (sleepTime - 10).toString());
                setSleepTime((prevTimer1) => prevTimer1 && prevTimer1 - 10);
            }, 10);
            if (sleepTime % 1000 === 0) {
                changeCustomer(0, sleepTime, true);
            }
        } else if (sleepTime === 0 && timeOut && person) {
            clearInterval(interval);
            setTimeOutState(false);
            setTime(ACTIVE_TIME);
            localStorage.setItem('timeOut', JSON.stringify(false));
            localStorage.setItem('sleepTime', '');
            changeCustomer(ACTIVE_TIME, 0, false);

        }
        return () => clearInterval(interval);

    }, [sleepTime, timeOut]);

    // custom cursor
    function handleMouseMove(ev: any) {
        setMousePosition({ left: ev.pageX - 5, top: ev.pageY + 10, });
    }


    useEffect(() => {
        // for cursor
        const userAgent = navigator.userAgent || navigator.vendor;
        // Check for common mobile platforms
        if (/android/i.test(userAgent)) {
            setIsMobile(true);
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            setIsMobile(true);
        }
        document.addEventListener('touchmove', handleMouseMove);

        return () => {
            document.removeEventListener('touchmove', handleMouseMove);
        }
    }, []);

    useEffect(() => {
        const startInterval = () => {
            if (!intervalRef.current) {
                getNextPickleball();
                intervalRef.current = window.setInterval(() => {
                    getNextPickleball();
                }, 1500);
            }
        };

        const stopInterval = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };


        // if screen is hidden to prevent animation
        const handleVisibilityChange = () => {
            if ((document.visibilityState === 'hidden') && isGameStarted) {
                cancelAllAnimations();
                stopInterval(); // Stop interval when tab is inactive
                setGameStarted(false);
            } else if ((document.visibilityState === 'visible') && isGameStarted && !timeOut) {
                // startInterval(); // Restart interval when tab becomes active
                // setGameStarted(true);
            }
        };

        if (!isGameStarted || timeOut) {
            cancelAllAnimations();
            stopInterval();
        }


        if (isGameStarted && !timeOut) {
            startInterval();
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            stopInterval();
        };
    }, [isGameStarted, timeOut]);


    const cancelAllAnimations = () => {
        ballType.forEach(ball => {
            if (cancelMap.has(ball)) {
                cancelMap.get(ball)!(); // Cancel the animation for each ball
            }
        });
    };



    // get next ball from back-end API
    const getNextPickleball = async () => {
        const newBallInfo: any | DropI = await getNextBall();
        if (newBallInfo?.status) {
            const value = newBallInfo.drop.reward?.data?.value ? newBallInfo.drop.reward?.data?.value : 0;
            const ball = new PickleballClass(windowWidth, windowHeight, value, newBallInfo.drop?.id, isMobile);

            setNextBall(ball);
            setCurrentAudio(getRandomElement(audioArray));
        }
    }
    useEffect(() => {
        if (nextBall) {
            set((prevItems) => [...prevItems, nextBall]);
        }
    }, [nextBall]);


    function getRandomElement(array: any[]): any {
        if (!array) return;
        return array[Math.floor(Math.random() * array.length)];
    }
    // animations

    // splash screen
    const springApi = useSpringRef();
    const transitionsFirst = useTransition(0, {
        ref: springApi,
        key: 0,
        from: { opacity: 1, scale: 1 },
        enter: { opacity: 0.5, scale: 1 },
        leave: { opacity: 0, scale: 0 },
        config: { duration: 1700 },
        onRest: (item) => {
            setOpen(true);
            dispatch(openApp());
        },
    });

    const transitionApi = useSpringRef();
    const isAppOpenedSaved = useSelector(state => (state as any).settings.isOpened);
    const isOpenedState = useMemo(() => !!isAppOpenedSaved, [isAppOpenedSaved]);

    // splash then ball animation
    useChain(open ? [springApi] : [springApi, transitionApi], [0, 0.8]); // Delay between the two animations

    // ball animation
    const transitionsTest = useTransition<PickleballI, AnimatedStyle>(ballType, {
        from: ball => ({ x: ball.left, y: '0', opacity: 1, height: ball.height }),
        keys: ball => ball.dropId,
        enter: (ball) => async (next, cancel) => {
            cancelMap.set(ball, cancel);  // Store the cancel function for each ball
            nextMap.set(ball, next);  // Store the next function for each ball
            await next({ x: ball.left, y: '85vh', height: ball.ballIncreasing });  // Move ball to random position
            await next({ x: ball.left, y: '140vh', height: 0, opacity: 0 });  // Move ball to random position
        },
        config: (item) => {
            // Set duration based on item value
            return { duration: item.duration, mass: 20, tension: 500, friction: 0 }
        },
        leave: ball => ({ opacity: 0, height: 0 }),
        onRest: (result, ctrl, ball) => {
            set(state => state.filter(b => b.dropId !== ball.dropId));  // Remove ball from the state
        },
    });

    // increase ball height on click
    const increaseHeightOnClick = async (ball: PickleballI) => {
        if (nextMap.has(ball)) {
            const next = nextMap.get(ball)!;

            // Animate height increase to 300px when clicked
            try {
                await next({ height: 300 });
            } catch (error) {
                if (error instanceof BailSignal) {
                    console.log('Animation was interrupted');
                }
            }
            // Now clear the ball
            setTimeout(() => {
                if (cancelMap.has(ball)) {
                    cancelMap.get(ball)!();  // Cancel and trigger removal
                }
            }, 200);

        }
    };

    // get click event from the Pickleball child component
    const updateParentClick = (event: { event: any, item: PickleballI }) => {
        increaseHeightOnClick(event.item);
        if (currentAudio.paused) {
            currentAudio.play();
        }
        const x = event.event.clientX;
        const y = event.event.clientY;
        if (event.item.points) {
            setPoints(event.item.points);
            setIsClicked(true);
        }
        setCoordinates({ x, y });

        setTimeout(() => {
            claimRequest(event.item);
        }, 200);

        setTimeout(() => {
            if (currentAudio.currentTime > 1) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
        }, 1200);
    };

    // get reward on click
    async function claimRequest(item: PickleballI) {
        if (localStorage.getItem('token')) {
            const resp: any = await getGameReward(item?.dropId);

            if (resp && resp.status && resp.reward?.data?.collection_id) {
                const rewardInfo = collectibles.find((item: any) => item.item_id === resp.reward.data.item_id);
                if (rewardInfo) {
                    setGameStarted(false);
                    setReward({ image: rewardInfo.image, asset: rewardInfo.asset, rarity: rewardInfo.rarity, id: rewardInfo.item_id, name: rewardInfo.name, price: rewardInfo.price });
                    setTimeout(() => {
                        if (winningAudio.paused) {
                            winningAudio.play();
                        }
                    }, 1000);
                    setTimeout(() => {
                        if (winningAudio.currentTime > 1) {
                            winningAudio.pause();
                            winningAudio.currentTime = 0;
                        }
                    }, 4000);
                }
            } else if (resp && resp.status && resp.reward?.data?.value) {
                const points = resp.reward.data.value;
                dispatch(incrementByAmount(points));
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
            }
        }
    }
    function refillBalance() {
        console.log('refill balance');
    }

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard:', text);
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    }

    return !open && !isOpenedState ? (
        <div style={{ display: 'flex', 'alignItems': 'center', 'height': '100%' }}>
            {transitionsFirst((style, i) => (
                <animated.div style={{ ...style, position: 'absolute', top: 0, left: 0, }}>
                    <Splash style={{ ...style }}
                    ></Splash>
                </animated.div>))}
        </div>) : (
        <TelegramScreen className={`aboutScreen`} id='gesuredZone'>
            <div className='main-container'>
                <div className="columns-holder">
                    <div className="column">
                        <div className="welcome">
                            <TelegramText className={'telegramMainTitle'}>Send Yo' Stake, Hear the Break</TelegramText>
                            <TelegramText className={'white-opacity'}>Yo, welcome to BRNZ Game, where the tables turn, No music corp control — this the spot you earn.</TelegramText>
                            <TelegramText className={'telegramHint'}>They hold the keys, but here's the mic, Where your tokens flip, and your beats ignite.</TelegramText>
                            <TelegramButton className={'block'} onClick={refillBalance}>Refill your balance</TelegramButton>
                            <div className="decor">
                                <img src={WelcomeImage} alt={'Welcome'} />
                            </div>
                        </div>

                        <div className="crypto-info">
                            <div className="crypto-box">
                                <div className="crypto-box__img"><img src={EthereumIcon} alt="EthereumIcon" /></div>
                                <div className="crypto-box__text">
                                    <TelegramText className={'telegramSubtitle'}>Ethereum</TelegramText>
                                    <TelegramText className={'white-opacity'}>0x8CEe92c96AED2f9A1c9706AFe7365347b425514a</TelegramText>
                                </div>
                                <div className="crypto-box__btn">
                                    <ClipboardButton onClick={() => copyToClipboard('0x8CEe92c96AED2f9A1c9706AFe7365347b425514a')}></ClipboardButton>
                                </div>
                            </div>
                            <div className="crypto-box">
                                <div className="crypto-box__img"><img src={BitcoinIcon} alt="BitcoinIcon" /></div>
                                <div className="crypto-box__text">
                                    <TelegramText className={'telegramSubtitle'}>Bitcoin</TelegramText>
                                    <TelegramText className={'white-opacity'}>bc1qh4supqae9zklyc7fd4jr48r260af4t9r6wj5h4</TelegramText>
                                </div>
                                <div className="crypto-box__btn">
                                    <ClipboardButton onClick={() => copyToClipboard('bc1qh4supqae9zklyc7fd4jr48r260af4t9r6wj5h4')}></ClipboardButton>
                                </div>
                            </div>
                            <div className="crypto-box">
                                <div className="crypto-box__img"><img src={SolanaIcon} alt="SolanaIcon" /></div>
                                <div className="crypto-box__text">
                                    <TelegramText className={'telegramSubtitle'}>Solana</TelegramText>
                                    <TelegramText className={'white-opacity'}>CUm7c4rFdZfu4Ec7PeNvPf59JStjcnP87vFhUkPpCSM2</TelegramText>
                                </div>
                                <div className="crypto-box__btn">
                                    <ClipboardButton onClick={() => copyToClipboard('CUm7c4rFdZfu4Ec7PeNvPf59JStjcnP87vFhUkPpCSM2')}></ClipboardButton>
                                </div>
                            </div>
                            <div className="crypto-box">
                                <div className="crypto-box__img"><img src={PolygonIcon} alt="PolygonIcon" /></div>
                                <div className="crypto-box__text">
                                    <TelegramText className={'telegramSubtitle'}>Polygon</TelegramText>
                                    <TelegramText className={'white-opacity'}>0x8CEe92c96AED2f9A1c9706AFe7365347b425514a</TelegramText>
                                </div>
                                <div className="crypto-box__btn">
                                    <ClipboardButton onClick={() => copyToClipboard('0x8CEe92c96AED2f9A1c9706AFe7365347b425514a')}></ClipboardButton>
                                </div>
                            </div>
                            <div className="crypto-box">
                                <div className="crypto-box__img"><img src={BaseIcon} alt="BaseIcon" /></div>
                                <div className="crypto-box__text">
                                    <TelegramText className={'telegramSubtitle'}>Base</TelegramText>
                                    <TelegramText className={'white-opacity'}>0x8CEe92c96AED2f9A1c9706AFe7365347b425514a</TelegramText>
                                </div>
                                <div className="crypto-box__btn">
                                    <ClipboardButton onClick={() => copyToClipboard('0x8CEe92c96AED2f9A1c9706AFe7365347b425514a')}></ClipboardButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="text-holder">
                            <h2>Why Play BRNZ Game?</h2>
                            <h3>Sick of watchin' big labels keepin' you down?</h3>
                            <TelegramText className={'white-opacity'}>BRNZ Game hands you the keys to rule this town. Tokens ain't just numbers — they become yo' sound, With every track, respect gets found.</TelegramText>
                            <h3>This ain't for the timid, this game's for the bold,</h3>
                            <TelegramText className={'white-opacity'}>Where tokens talk and the charts unfold. Got beats? Got flows? Now's the time, Stake it, make it—every stack's a climb.</TelegramText>
                        </div>
                        <div className="text-holder">
                            <h2>How It Works</h2>
                            <h3>Drop Yo' Tokens</h3>
                            <TelegramText className={'white-opacity'}>Yo, send 'em to the vault, let that power hit, The bigger the stake, the faster you sit At the front of the line, where the big tracks play, And high rollers first, where the legends stay.</TelegramText>
                            <h3>Get Yo' Track</h3>
                            <TelegramText className={'white-opacity'}>Your stake gets flipped to a track with fire, A custom beat that rises higher, Straight to the board, yo' anthem lands, For the fam to hear, for respect and fans.</TelegramText>
                            <h3>Climb the Charts with Power Points</h3>
                            <TelegramText className={'white-opacity'}>Every vote, every boost, yo' song's embraced, Power Points stackin', keepin' you laced. Fans can drop tokens, keepin' you high, Pushin' yo' anthem to touch the sky.</TelegramText>
                            <h3>Rule the Board, Take the Crown</h3>
                            <TelegramText className={'white-opacity'}>Hold #1, make that board yo' throne, One week strong, and the win's yo' own. With AI push, the world gon' see— This BRNZ Game life was meant to be.</TelegramText>
                            <h4>So what you waitin' for? Step in the vault, Bring yo' stake, hear the bass default. Drop that line, make the beat bang loud, BRNZ Game's the place — stand out from the crowd.</h4>
                        </div>
                    </div>
                </div>
            </div>
            <MainNavigation currentPage={'about'} />

        </TelegramScreen>);
});

export default AboutPage;
