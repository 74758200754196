import React, { useState, useEffect, useRef } from 'react';
import Sketch from 'react-p5';
import "p5/lib/addons/p5.sound";
import { FRONTEND_URL } from "../logic/server/Variables";
import TelegramText from '../components/kit/Text/TelegramText';
import PPIcon from "../assets/icons/pp-small.svg";
import './P5Game.scss';
import { rateProductWithClaimedPoints, sendPointsToServer } from '../logic/server/api';
import { ReactComponent as PlayIcon } from '../assets/icons/play-filled-alt.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/pause-filled.svg';
import { ReactComponent as SkipBackIcon } from '../assets/icons/skip-back.svg';
import { ReactComponent as SkipForwardIcon } from '../assets/icons/skip-forward.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/icon-x.svg';
import { ReactComponent as TelegramIcon } from '../assets/icons/icon-telegram.svg';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton } from 'react-share';
import WelcomeImage from '../assets/backgrounds/leaderboard-bg.jpeg';
import iconX from '../assets/icons/icon-x.svg';
import iconWeb from '../assets/icons/icon-web.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let amplitude;
let currentTrackIndex = 0;

function P5Game({ personPoints, setPersonPoints, tracksList, onTrackChange }) {
    const [tracks, setTracks] = useState([]);
    const [audioUrl, setAudioUrl] = useState(null);
    const [lastChange, setLastChange] = useState(0);
    const [showLastChange, setShowLastChange] = useState(false);
    const [shadowCircleSize, setShadowCircleSize] = useState(150);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [currentInnerCircleSize, setCurrentInnerCircleSize] = useState(160);
    const [shrinkSpeed, setShrinkSpeed] = useState(1.2);
    const [inflateFactor, setInflateFactor] = useState(1.5);
    const [tolerance, setTolerance] = useState(14);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isGreen, setIsGreen] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [greenDuration, setGreenDuration] = useState(500);
    const [redDuration, setRedDuration] = useState(300);
    const [tokenLogo, setTokenLogo] = useState(null)
    const greenTimeoutRef = useRef(null);
    const redTimeoutRef = useRef(null);
    const isTrackLoading = useRef(false);
    const [PPLoading, setPPLoading] = useState(true);
    const p5ref = useRef(null);
    const audioRef = useRef(null);
    const [showShareTooltip, setShowShareTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const isManualAction = useRef(false);
    const powerPoints = useRef(0);

    useEffect(() => {
        if (tracksList && tracksList.length > 0) {
            setTracks(tracksList);
            currentTrackIndex = 0;
            setAudioUrl(tracksList[currentTrackIndex].url);
        }
    }, [tracksList]);

    useEffect(() => {
        if (personPoints !== null) {
            setPPLoading(false);
        }
    }, [personPoints]);

    useEffect(() => {
        if (isGreen) {
            greenTimeoutRef.current = setTimeout(() => {
                setIsGreen(false);
            }, greenDuration);
        }
        return () => clearTimeout(greenTimeoutRef.current);
    }, [isGreen, greenDuration]);

    useEffect(() => {
        if (isRed) {
            redTimeoutRef.current = setTimeout(() => {
                setIsRed(false);
            }, redDuration);
        }
        return () => clearTimeout(redTimeoutRef.current);
    }, [isRed, redDuration]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowShareTooltip(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tooltipRef]);

    const setup = (p5, canvasParentRef) => {
        p5ref.current = p5;
        p5.createCanvas(360, 360).parent(canvasParentRef);
        p5.noStroke();
        p5.ellipseMode(p5.CENTER);

        setupAudio(audioUrl);
    };

    const draw = (p5) => {
        p5.background(0);
        p5.noFill();
        p5.stroke(255, 255, 255, 40);
        p5.strokeWeight(1);
        p5.ellipse(p5.width / 2, p5.height / 2, shadowCircleSize * 2);

        const level = amplitude && audioRef.current && audioRef.current.isPlaying() ? amplitude.getLevel() : 0;

        const minRadius = 80;
        const maxRadius = 111 * inflateFactor;
        const targetRadius = p5.map(level, 0, 0.25, minRadius, maxRadius);

        if (targetRadius > currentInnerCircleSize) {
            setCurrentInnerCircleSize(targetRadius);
        } else {
            setCurrentInnerCircleSize(Math.max(minRadius, currentInnerCircleSize - shrinkSpeed));
        }

        let fillColor, strokeColor;
        if (isRed) {
            fillColor = createGradient(p5, 'rgba(255, 4, 4, 0.25)', 'rgba(255, 4, 4, 0)');
            strokeColor = p5.color(255, 4, 4);
        } else if (isGreen) {
            fillColor = createGradient(p5, 'rgba(4, 255, 4, 0.25)', 'rgba(4, 255, 4, 0)');
            strokeColor = p5.color(4, 255, 4);
        } else if (Math.abs(currentInnerCircleSize - shadowCircleSize) < tolerance) {
            fillColor = createGradient(p5, 'rgba(4, 255, 4, 0.25)', 'rgba(4, 255, 4, 0)');
            strokeColor = p5.color(4, 255, 4);
            if (!isGreen) {
                setIsGreen(true);
            }
        } else {
            fillColor = createGradient(p5, 'rgba(169, 58, 255, 0.25)', 'rgba(169, 58, 255, 0)');
            strokeColor = p5.color(169, 58, 255);
        }

        if (tokenLogo) {
            const naturalWidth = tokenLogo.width;
            const naturalHeight = tokenLogo.height;
            const maxIconSize = currentInnerCircleSize * 2;
            const minIconSize = currentInnerCircleSize;
            const scale = Math.min(maxIconSize / naturalWidth, maxIconSize / naturalHeight, 1);
            const iconWidth = Math.max(naturalWidth * scale, minIconSize);
            const iconHeight = Math.max(naturalHeight * scale, minIconSize);

            const amplitudeScale = 1;
            const pulsatingIconWidth = iconWidth * amplitudeScale;
            const pulsatingIconHeight = iconHeight * amplitudeScale;

            p5.image(tokenLogo, p5.width / 2 - pulsatingIconWidth / 2, p5.height / 2 - pulsatingIconHeight / 2, pulsatingIconWidth, pulsatingIconHeight);
        }

        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.fill(255);

        p5.drawingContext.fillStyle = fillColor;
        p5.stroke(strokeColor);
        p5.strokeWeight(2);
        p5.ellipse(p5.width / 2, p5.height / 2, currentInnerCircleSize * 2);
    };

    const createGradient = (p5, color1, color2) => {
        const gradient = p5.drawingContext.createLinearGradient(0, 0, 0, p5.height);
        gradient.addColorStop(1, color1);
        gradient.addColorStop(0, color2);
        return gradient;
    };

    const mousePressed = (p5) => {
        const currentTime = p5.millis();
        const mouseX = p5.mouseX;
        const mouseY = p5.mouseY;
        const centerX = p5.width / 2;
        const centerY = p5.height / 2;
        const distance = p5.dist(mouseX, mouseY, centerX, centerY);

        if (distance > currentInnerCircleSize || !audioRef.current || !audioRef.current.isPlaying() || currentTime - lastClickTime < 200) {
            return;
        }

        setLastClickTime(currentTime);

        if (isGreen) {
            powerPoints.current += tracks[currentTrackIndex].reward;
            setLastChange(tracks[currentTrackIndex].reward);
            setShowLastChange(true);
        } else {
            powerPoints.current = Math.max(powerPoints.current - tracks[currentTrackIndex].reward, 0);
            setLastChange(-tracks[currentTrackIndex].reward);
            setShowLastChange(true);
            setIsRed(true);
            setIsGreen(false);
        }

        setTimeout(() => {
            setShowLastChange(false);
        }, 400);
    };

    const handlePlayPause = () => {
        isManualAction.current = true;

        if (audioRef.current.isPlaying()) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
            isManualAction.current = false;
        }
    };

    const handleTrackEnd = () => {
        if (isManualAction.current) {
            isManualAction.current = false;
            return;
        }
        nextTrack();
    };

    const setupAudio = (url) => {
        if (!p5ref) {
            return;
        }
        isTrackLoading.current = true;
        audioRef.current = p5ref.current.loadSound(url, () => {
            isTrackLoading.current = false;
            if (tracks[currentTrackIndex].plays === 0) {
                setIsPlaying(false);
                return;
            }
            audioRef.current.play();
            if (audioRef.current.isPlaying()) {
                setIsPlaying(true);
            }
            isManualAction.current = false;
        });

        audioRef.current.onended(() => {
            handleTrackEnd();
        });

        amplitude = new p5ref.current.constructor.Amplitude();
        amplitude.setInput(audioRef.current);

        p5ref.current.loadImage(tracks[currentTrackIndex].image, (img) => {
            setTokenLogo(img);
        });

        onTrackChange(tracks[currentTrackIndex].slug);
        setShowShareTooltip(false);
    };

    const claimPoints = async () => {
        if (powerPoints.current > 0) {
            await rateProductWithClaimedPoints(powerPoints.current, tracks[currentTrackIndex].id);
            await sendPointsToServer(powerPoints.current);
            setPersonPoints(prevPoints => prevPoints + powerPoints.current);
            powerPoints.current = 0;
            tracks[currentTrackIndex].plays -= 1;
        }
    };

    const nextTrack = async () => {
        if (isTrackLoading.current) {
            console.log('Track is still loading, please wait');
            return;
        }
        isManualAction.current = true;

        if (audioRef.current && audioRef.current.isPlaying()) {
            audioRef.current.stop();
        }

        await claimPoints();

        currentTrackIndex = (currentTrackIndex + 1) % tracks.length;
        setAudioUrl(tracks[currentTrackIndex].url);
        setupAudio(tracks[currentTrackIndex].url);
    };

    const previousTrack = async () => {
        if (isTrackLoading.current) {
            console.log('Track is still loading, please wait');
            return;
        }
        isManualAction.current = true;

        if (audioRef.current && audioRef.current.isPlaying()) {
            audioRef.current.stop();
        }

        await claimPoints();

        currentTrackIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
        setAudioUrl(tracks[currentTrackIndex].url);
        setupAudio(tracks[currentTrackIndex].url);
    };

    useEffect(() => {
        return () => {
            isManualAction.current = true;
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current = null;
            }
            if (powerPoints.current > 0) {
                rateProductWithClaimedPoints(powerPoints.current, tracks[currentTrackIndex].id);
                sendPointsToServer(powerPoints.current);
                setPersonPoints(prevPoints => prevPoints + powerPoints.current);
                powerPoints.current = 0;
                tracks[currentTrackIndex].plays -= 1;
            }
        };
    }, []);

    const generateShareUrl = (trackId) => {
        return `${FRONTEND_URL}/share/${trackId}`;
    };

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Copied to clipboard!');
        }).catch(err => {
            toast.error('Failed to copy!');
        });
    }

    const navigateToWeb = (url) => {
        window.open(url, '_blank')
    }

    return (
        <div className='welcome-holder'>
            <div className="columns-holder style01">
                <div className="column">
                    <div className="welcome">
                        {tracks.length > 0 && (
                            <>
                                <TelegramText className={'telegramMainTitle'}>
                                    {tracks[currentTrackIndex].name}
                                </TelegramText>
                                <TelegramText className={'telegramSubtitle'}>
                                    {`${tracks[currentTrackIndex].reward} PP per click, ${tracks[currentTrackIndex].plays || 0} play${tracks[currentTrackIndex].plays === 1 ? '' : 's'} left`}
                                </TelegramText>
                            </>
                        )}
                        <div className="decor">
                            <img src={WelcomeImage} alt={'Welcome'} />
                        </div>
                        <div className="social-holder">
                            <TelegramText className={'telegramPrimaryText'}>
                                <span style={{ display: 'inline-block', position: 'relative' }}>
                                    <img src={PPIcon} alt={'Powder'} />
                                    {PPLoading ? (
                                        <span className="spinner-small"></span>
                                    ) : (
                                        `${personPoints + powerPoints.current} PP`
                                    )}
                                    {showLastChange && (
                                        <span className={`lastChange ${lastChange > 0 ? 'positive' : 'negative'}`}>
                                            {lastChange > 0 ? ` +${lastChange}` : ` ${lastChange}`}
                                        </span>
                                    )}
                                </span>
                            </TelegramText>
                            <div className="social-icons">
                                <button onClick={() => navigateToWeb(tracks[0].token_url)} className="back-button">
                                    <img src={iconX} alt="X" className="icon" /> <span className="social-text">Follow</span>
                                </button>
                                <button onClick={() => navigateToWeb(tracks[0].project_url)} className="back-button">
                                    <img src={iconWeb} alt="Web" className="icon" /> <span className="social-text">Visit Website</span>
                                </button>
                            </div>
                        </div>

                        <div className="share-holder">
                            <div className='buttons-holder'>
                                <div className='buttons-frame'>
                                    <button
                                        className={`outlined ${isGreen ? 'green' : ''} ${isRed ? 'red' : ''}`}
                                        onClick={handlePlayPause}
                                        disabled={tracks.length === 0 || tracks[currentTrackIndex].plays === 0}
                                    >
                                        {isPlaying ? <PauseIcon /> : <PlayIcon />}
                                    </button>
                                    {/* <TelegramButton style={{ flexGrow: '1' }}><BuyTokenIon /> Buy RAPB Token</TelegramButton> */}
                                    <button
                                        className={`outlined ${isGreen ? 'green' : ''} ${isRed ? 'red' : ''}`}
                                        onClick={previousTrack}
                                    >
                                        <SkipBackIcon />
                                    </button>
                                    <button
                                        className={`outlined ${isGreen ? 'green' : ''} ${isRed ? 'red' : ''}`}
                                        onClick={nextTrack}
                                    >
                                        <SkipForwardIcon />
                                    </button>
                                    <button
                                        className={`outlined visible-mobile`}
                                        onClick={() => setShowShareTooltip(!showShareTooltip)}
                                    >
                                        <ShareIcon />
                                    </button>
                                </div>
                                {showShareTooltip && (
                                    <div className="share-tooltip" ref={tooltipRef}>
                                        <FacebookShareButton url={generateShareUrl(tracks[currentTrackIndex]?.slug)} quote={`Check this out! ${tracks[currentTrackIndex]?.name}`}>
                                            <FacebookIcon size={32} round={true.toString()} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={generateShareUrl(tracks[currentTrackIndex]?.slug)} title={`Check this out! ${tracks[currentTrackIndex]?.name}`}>
                                            <TwitterIcon size={32} round={true.toString()} />
                                        </TwitterShareButton>
                                        <TelegramShareButton url={generateShareUrl(tracks[currentTrackIndex]?.slug)} title={`Check this out! ${tracks[currentTrackIndex]?.name}`}>
                                            <TelegramIcon size={32} round={true.toString()} />
                                        </TelegramShareButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="share-container">
                        <button className="share-button" onClick={() => copyToClipboard(generateShareUrl(tracks[currentTrackIndex]?.slug))}>
                            <ShareIcon />
                            Copy to clipboard
                        </button>
                        <div className="share-buttons">
                            <FacebookShareButton url={generateShareUrl(tracks[currentTrackIndex]?.slug)} quote={`Check this out! ${tracks[currentTrackIndex]?.name}`}>
                                <FacebookIcon size={32} round={true.toString()} />
                            </FacebookShareButton>
                            <TwitterShareButton url={generateShareUrl(tracks[currentTrackIndex]?.slug)} title={`Check this out! ${tracks[currentTrackIndex]?.name}`}>
                                <TwitterIcon size={32} round={true.toString()} />
                            </TwitterShareButton>
                            <TelegramShareButton url={generateShareUrl(tracks[currentTrackIndex]?.slug)} title={`Check this out! ${tracks[currentTrackIndex]?.name}`}>
                                <TelegramIcon size={32} round={true.toString()} />
                            </TelegramShareButton>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="game-wrapper">
                        {tracks.length > 0 && (
                            <Sketch setup={setup} draw={draw} mousePressed={mousePressed} />
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}

                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default P5Game;