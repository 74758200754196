import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import { createProduct, uploadFileImage, uploadFileAudio } from '../../logic/server/api';

import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import WelcomeImage from '../../assets/backgrounds/leaderboard-bg.jpeg';

import { ReactComponent as AiIcon } from '../../assets/icons/ai-icon.svg';
import { ReactComponent as CurrencyDollar } from '../../assets/icons/currency-dollar.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image-icon.svg';
import { ReactComponent as ShortParagraph } from '../../assets/icons/short-paragraph.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as PlayFilled } from '../../assets/icons/play-filled.svg';
import { ReactComponent as StopFilled } from '../../assets/icons/stop-filled.svg';
import { ReactComponent as TextCreating } from '../../assets/icons/text-creation.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/icon-copy.svg';
import { ReactComponent as DirectLink } from '../../assets/icons/direct-link.svg';
import { ReactComponent as MusicAdd } from '../../assets/icons/music-add.svg';
import { PATH_CHARTS, PATH_TASKS } from '../../constants/Paths';
import { BOT_ID, CATEGORY_ID } from '../../logic/server/Variables';

const CreatePage = () => {
    const navigate = useNavigate();
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState(3);
    const [updateCounter, setUpdateCounter] = useState(18);
    const [projectLogoPreview, setProjectLogoPreview] = useState(null);
    const [projectLogoName, setProjectLogoName] = useState('');
    const [projectTrackUrl, setProjectTrackUrl] = useState(null);
    const [projectTrackName, setProjectTrackName] = useState('');
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [isAudioUploading, setIsAudioUploading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const audioRef = useRef(null);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            aiRank: 'gold'
        }
    });

    const handlePlayPause = (track) => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            setCurrentTrack(track);
            setIsPlaying(true);
            audioRef.current.src = track.url;
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
            });
        }
    };

    const handleProgress = (progress) => {
        setProgress(progress);
    };

    const handleFileChange = async (e, type) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            let response;
            if (type === 'image') {
                setIsImageUploading(true);
                response = await uploadFileImage(formData);
                if (response.status) {
                    setProjectLogoPreview(response.url);
                    setProjectLogoName(file.name);
                }
                setIsImageUploading(false);
            } else if (type === 'audio') {
                setIsAudioUploading(true);
                response = await uploadFileAudio(formData);
                if (response.status) {
                    setProjectTrackUrl(response.url);
                    setProjectTrackName(file.name);
                }
                setIsAudioUploading(false);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setIsImageUploading(false);
            setIsAudioUploading(false);
        }
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('bot_id', BOT_ID);
        formData.append('category_id', CATEGORY_ID);
        formData.append('name', data.tokenName);
        formData.append('attributes[name]', data.tokenName);
        formData.append('attributes[price]', 1);
        formData.append('attributes[image]', projectLogoPreview);
        formData.append('attributes[project_url]', data.projectUrl);
        formData.append('attributes[token_url]', data.tokenUrl);
        formData.append('attributes[logo_text]', data.logoText);
        formData.append('attributes[url]', projectTrackUrl);
    
        try {
            const response = await createProduct(formData);
            if (response.status) {
                navigate('/'+response.product_id);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePublish = async () => {
    };

    const handleUpdate = async () => {
        if (updateCounter > 0) {
            setUpdateCounter(updateCounter - 1);
        }
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                setProgress(progress);
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, []);

    return (
        <TelegramScreen className={``} id='gesuredZone'>
            <div className='main-container'>
                <div className='welcome-holder'>
                    <div className="columns-holder">
                        <div className="column">
                                {step === 1 ? (
                                    <div className="welcome style01">
                                        <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>New track</span></TelegramText>
                                        <TelegramText className={'white-opacity'}>Fill the form to manually create a new track. More investment and higher rank will result in a better song quality. Do not forget about detailed prompts for the lyrics.</TelegramText>
                                        <TelegramButton className={'block hidden-mobile'} onClick={handleSubmit(onSubmit)}>Generate</TelegramButton>
                                        <div className="decor">
                                            <img src={WelcomeImage} alt={'Welcome'} />
                                        </div>
                                    </div>
                                ) : step === 2 ? (
                                    <div className="preview-holder">
                                        <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>Review results</span></TelegramText>
                                        <TelegramText className={'mobile-small white-opacity'}>Congratulations! Your new track successfully generated. Now, you can listen and publish it, or re-generate, if current result is not good enough.</TelegramText>
                                        <div className="preview-block">
                                            <div className="welcome style01">
                                                {currentTrack && (
                                                    <>
                                                        <img width={48} height={48} src={currentTrack.logoImage} alt={currentTrack.logoText} />
                                                        <TelegramText className={'telegramSubTitle'}><span className={'text-bold-roboto'}>{currentTrack.logoText}</span></TelegramText>
                                                        <TelegramText className={'white-opacity'}>{currentTrack.title}</TelegramText>
                                                    </>
                                                )}
                                                {currentTrack && (
                                                    <div className="preview" onClick={() => handlePlayPause(currentTrack)}>
                                                        {isPlaying ? <StopFilled /> : <PlayFilled />}
                                                        Preview this track
                                                    </div>
                                                )}
                                                <div className="decor">
                                                    <img src={WelcomeImage} alt={'Welcome'} />
                                                </div>
                                            </div>
                                            <div className="hidden-mobile">
                                                <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>Review results</span></TelegramText>
                                                <TelegramText className={'mobile-small white-opacity'}>Congratulations! Your new track successfully generated. Now, you can listen and publish it, or re-generate, if current result is not good enough.</TelegramText>
                                                <div className="cta-buttons">
                                                    <TelegramButton className={'block hidden-mobile'} onClick={handlePublish} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Publish'}</TelegramButton>
                                                    <TelegramButton className={'block outlined hidden-mobile'} onClick={handleUpdate} disabled={updateCounter === 0}>Update ({updateCounter})</TelegramButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="welcome style01">
                                        <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>New track</span></TelegramText>
                                        <TelegramText className={'white-opacity'}>Fill the form to manually create a new track. More investment and higher rank will result in a better song quality. Do not forget about detailed prompts for the lyrics.</TelegramText>
                                        <TelegramButton className={'block hidden-mobile'} onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Publish'}</TelegramButton>
                                        <div className="decor">
                                            <img src={WelcomeImage} alt={'Welcome'} />
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="column">
                            <form className="form-holder" onSubmit={handleSubmit(onSubmit)}>
                                {step === 1 && <div className="form-item">
                                    <TelegramText className={'regularText'}><b><AiIcon /> Select AI rank</b></TelegramText>
                                    <div className="radio-group">
                                        <label className={`radio-button bronze ${watch('aiRank') === 'bronze' ? 'checked' : ''}`}>
                                            Bronze
                                            <input type="radio" name="aiRank" value="bronze" {...register('aiRank')} disabled={step === 2} />
                                        </label>
                                        <label className={`radio-button silver ${watch('aiRank') === 'silver' ? 'checked' : ''}`}>
                                            Silver
                                            <input type="radio" name="aiRank" value="silver" {...register('aiRank')} disabled={step === 2} />
                                        </label>
                                        <label className={`radio-button gold ${watch('aiRank') === 'gold' ? 'checked' : ''}`}>
                                            Gold
                                            <input type="radio" name="aiRank" value="gold" {...register('aiRank')} disabled={step === 2} />
                                        </label>
                                    </div>
                                    <TelegramText className={'white-opacity'}>Human made song with professional mastering</TelegramText>
                                </div>}
                                {step === 1 && <div className="form-item">
                                    <TelegramText className={'regularText'}><b><CurrencyDollar /> Token investment</b></TelegramText>
                                    <div className="form-control">
                                        <input className={errors.investment ? 'contol-error' : ''} type="number" placeholder="Enter investment" {...register('investment', { required: true })} disabled={step === 2} />
                                        <span className="currency">USD</span>
                                    </div>
                                    {errors.investment && <span className="error">Investment is required</span>}
                                </div>}
                                {step === 1 || step === 2 && <div className="form-item">
                                    <TelegramText className={`regularText`}>
                                        <label htmlFor="lyrics"><b><ShortParagraph /> {step === 1 ? 'Prompt for the lyrics' : 'Update lyrics prompt (optionally)'}</b></label>
                                    </TelegramText>
                                    <textarea 
                                        id="lyrics" 
                                        className={`${errors.lyrics ? 'contol-error' : ''}`} 
                                        placeholder="Prompt or tags" 
                                        {...register('lyrics', { required: true, minLength: 10, maxLength: 500 })} 
                                    />
                                    {errors.lyrics && <span className="error">Lyrics must be between 10 and 500 characters</span>}
                                </div>}
                                {step === 1 && <div className="form-item">
                                    <TelegramText className={'regularText'}><b><ImageIcon /> Project logo</b></TelegramText>
                                    <input type="file" id="file" {...register('file', {
                                        required: true,
                                        validate: {
                                            size: value => value[0]?.size <= 256 * 1024 * 1024 || 'File size must be less than 256MB',
                                            format: value => ['image/png', 'image/svg+xml'].includes(value[0]?.type) || 'File format must be PNG or SVG'
                                        }
                                    })} style={{ display: 'none' }} disabled={step === 2} />
                                    <label htmlFor="file" className={`file-textarea-label ${errors.file ? 'contol-error' : ''}`}>
                                        <FileIcon />
                                        {watch('file') && watch('file')[0] ? watch('file')[0].name : 'Select file'} {!watch('file') && <span>(PNG or SVG)</span>}
                                    </label>
                                    {errors.file && <span className="error">{errors.file.message}</span>}
                                </div>}
                                {step === 1 && (
                                    <div className="cta-buttons">
                                        <TelegramButton className={'block visible-mobile'} type="submit" disabled={isSubmitting}>Generate</TelegramButton>
                                        <TelegramButton className={'block outlined visible-mobile'} onClick={() => handleNavigate(PATH_CHARTS)} disabled={isSubmitting}>Cancel</TelegramButton>
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="cta-buttons">
                                        <TelegramButton className={'block visible-mobile'} onClick={handlePublish}>{isSubmitting ? 'Submitting...' : 'Publish'}</TelegramButton>
                                        <TelegramButton className={'block outlined visible-mobile'} onClick={handleUpdate} disabled={updateCounter === 0}>Update ({updateCounter})</TelegramButton>
                                    </div>
                                )}
                                {step === 3 && <>
                                    <div className="form-item">
                                        <TelegramText className={'regularText'}><b><TextCreating /> Project and Token name</b></TelegramText>
                                        <div className="form-control">
                                            <input className={errors.tokenName ? 'contol-error' : ''} type="text" placeholder="Project" {...register('tokenName', { required: true })} />
                                            <span className="currency">TKN</span>
                                        </div>
                                        {errors.tokenName && <span className="error">Token name is required</span>}
                                    </div>
                                    <div className="form-item">
                                        <TelegramText className={'regularText'}>
                                            <b><ShareIcon /> Project URL</b>
                                        </TelegramText>
                                        <div className="form-control">
                                            <input
                                                type="text"
                                                placeholder="https://"
                                                className={errors.projectUrl ? 'contol-error' : ''}
                                                {...register('projectUrl', {
                                                    required: 'Project URL is required',
                                                    pattern: {
                                                        value: /^https:\/\/.*/i,
                                                        message: 'Project URL must start with https://'
                                                    }
                                                })}
                                            />
                                        </div>
                                        {errors.projectUrl && <span className="error">{errors.projectUrl.message}</span>}
                                    </div>
                                    <div className="form-item">
                                        <TelegramText className={'regularText'}>
                                            <b><DirectLink /> Token URL</b>
                                        </TelegramText>
                                        <div className="form-control">
                                            <input
                                                type="text"
                                                placeholder="https://"
                                                className={errors.tokenUrl ? 'contol-error' : ''}
                                                {...register('tokenUrl', {
                                                    required: 'Token URL is required',
                                                    pattern: {
                                                        value: /^https:\/\/.*/i,
                                                        message: 'Token URL must start with https://'
                                                    }
                                                })}
                                            />
                                        </div>
                                        {errors.tokenUrl && <span className="error">{errors.tokenUrl.message}</span>}
                                    </div>
                                    <div className="form-item">
                                        <TelegramText className={'regularText'}>
                                            <b><DirectLink /> Logo Text</b>
                                        </TelegramText>
                                        <div className="form-control">
                                            <input
                                                type="text"
                                                placeholder="Enter logo text"
                                                className={errors.logoText ? 'contol-error' : ''}
                                                {...register('logoText', {
                                                    required: 'Logo Text is required'
                                                })}
                                            />
                                        </div>
                                        {errors.logoText && <span className="error">{errors.logoText.message}</span>}
                                    </div>
                                    <div className="form-item">
                                        <TelegramText className={'regularText'}><b><ImageIcon /> Project logo</b></TelegramText>
                                        <input type="file" id="projectLogo" {...register('projectLogo', {
                                            required: true,
                                            validate: {
                                                size: value => value && value[0]?.size <= 256 * 1024 * 1024 || 'File size must be less than 256MB',
                                                format: value => value && ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/svg+xml'].includes(value[0]?.type) || 'File format must be PNG, JPEG, JPG, WEBP, or SVG'
                                            }
                                        })} style={{ position: 'absolute', opacity: 0 }} onChange={(e) => handleFileChange(e, 'image')} />
                                        <label htmlFor="projectLogo" className={`file-textarea-label ${errors.projectLogo ? 'contol-error' : ''}`}>
                                            <FileIcon />
                                            {watch('projectLogo') ? watch('projectLogo').name : 'Select file'} {!watch('projectLogo') && <span>(PNG, JPEG, JPG, WEBP, or SVG)</span>}
                                            {projectLogoPreview && <img src={projectLogoPreview} alt="Project Logo Preview" style={{ marginTop: '10px', maxWidth: '100%' }} />}
                                        </label>
                                        {errors.projectLogo && <span className="error">{errors.projectLogo.message}</span>}
                                        {projectLogoName && <div>{projectLogoName}</div>}
                                    </div>
                                    <div className="form-item">
                                        <TelegramText className={'regularText'}><b><MusicAdd /> Upload your track</b></TelegramText>
                                        <input type="file" id="projectTrack" {...register('projectTrack', {
                                            required: true,
                                            validate: {
                                                size: value => value && value[0]?.size <= 256 * 1024 * 1024 || 'File size must be less than 256MB',
                                                format: value => value && ['audio/mp3', 'audio/wav', 'audio/mpeg'].includes(value[0]?.type) || 'File format must be MP3 или WAV'
                                            }
                                        })} style={{ position: 'absolute', opacity: 0 }} onChange={(e) => handleFileChange(e, 'audio')} />
                                        <label htmlFor="projectTrack" className={`file-input-label ${errors.projectTrack ? 'contol-error' : ''}`}>
                                            {watch('projectTrack') && watch('projectTrack')[0] ? watch('projectTrack')[0].name : 'Select file...'} {!watch('projectTrack') && <span>(MP3 или WAV)</span>}
                                        </label>
                                        {errors.projectTrack && <span className="error">{errors.projectTrack.message}</span>}
                                        {projectTrackName && <div>{projectTrackName}</div>}
                                    </div>
                                    <div className="cta-buttons">
                                        <TelegramButton className={'block visible-mobile'} onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Publish'}</TelegramButton>
                                        <TelegramButton className={'block outlined visible-mobile'} onClick={() => handleNavigate(PATH_CHARTS)} disabled={isSubmitting}>Cancel</TelegramButton>
                                    </div>
                                </>}
                            </form>
                        </div>
                    </div>
                </div>
                <MainNavigation currentPage={'create'} />
            </div>
            <audio ref={audioRef} />
            {currentTrack && (
                <div className="preview-block">
                    <div className="welcome style01">
                        <TelegramText className={'telegramSubTitle'}><span className={'text-bold-roboto'}>{currentTrack.title}</span></TelegramText>
                        <div className="preview" onClick={handlePlayPause}>
                            {isPlaying ? <StopFilled /> : <PlayFilled />}
                            Preview this track
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>
    );
};

export default CreatePage;