import React, { useEffect, useState } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { claimPoints, setInteraction } from "../../logic/server/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTelegram } from "../../hooks/useTelegram";
import TelegramButton from '../../components/kit/Button/TelegramButton';
import TelegramText from '../../components/kit/Text/TelegramText';
import { fetchTasks } from '../../components/app/utils/tasks.utils';
import {TasksList, TaskType} from './TasksList';
import { debounce } from 'lodash';

import WelcomeImage from '../../assets/welcome-image2.png';
import { ReactComponent as ClaimIcon } from "../../assets/icons/claim.svg";

const TasksPage = () => {
    const { webApp, user } = useTelegram();
    const [tasks, setTasks] = useState([]);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);

    useEffect(() => {
        const loadTasks = async () => {
            const tasksData = await fetchTasks();
            setTasks(tasksData);
        };
        const debouncedLoadTasks = debounce(loadTasks, 300);
        debouncedLoadTasks();
        return () => {
            debouncedLoadTasks.cancel();
        };
    }, [linkOpened, taskClaimed]);

    async function handleInteraction(task) {
        if (task?.conditions[0]?.type === TaskType.referral) {
            window.location.assign('/earned');
            return;
        }
        if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed')) {
            const res = await setInteraction(task?.conditions[0].data?.link);
            webApp.openLink(task?.conditions[0].data?.link);
            if (res?.status) {
                setLinkOpened([{ id: task.id, status: true }]);
            }
        } else if (task?.completion.claimable) {
            handleClaim(task);
            toast.success(`You claimed ${task?.reward?.data?.value} coins successfully!`);
        }
    }

    async function handleClaim(task) {
        const res = await claimPoints(localStorage.getItem('token'), task.id);
        if (res?.status) {
            setTaskClaimed([{ id: task.id, status: true }]);
        }
    }

    function claimAllRewards() {
        tasks.forEach(task => task.completion.claimable && handleClaim(task));
    }

    return (
        <TelegramScreen className={``} id='gesuredZone'>
            <div className='main-container'>
                <div className='welcome-holder'>
                    <div className="columns-holder">
                        <div className="column">
                            <div className="welcome">
                                <TelegramText className={'telegramMainTitle'}>
                                    <span className={'text-bold-roboto'}>
                                        {tasks?.filter(task => !task.completion.claimable).length} {tasks?.length > 1 ? 'tasks' : 'task'} available
                                    </span>
                                </TelegramText>
                                <TelegramButton className={'block'} onClick={claimAllRewards}>
                                    <ClaimIcon /> Claim all rewards
                                </TelegramButton>
                                <div className="decor">
                                    <img src={WelcomeImage} alt={'Welcome'} />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className='cards-container-dim'>
                                <div className={'cards-container--top'}>
                                    <div className={'cards__container'}>
                                        <TasksList tasks={tasks} handleInteraction={handleInteraction} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MainNavigation currentPage={'tasks'} />
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>
    );
};

export default TasksPage;