import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';


const adaptTracksToHierarchy = (tracks) => {
    return {
        name: "Tracks",
        children: tracks.map(track => ({
            name: track.name,
            logoText: track.logo_text,
            votes: (track.votes / 100000).toFixed(2),
            value: track.reward,
            reward: track.reward,
            url: track.url,
        }))
    };
};

const LeaderBoardGrid = ({ tracks, handlePlayPause }) => {
    const gridRef = useRef(null);

    useEffect(() => {
        renderGrid();
    }, [tracks]);

    const renderGrid = () => {
        const width = 928;
        const height = 924;
        const padding = 2;

        // This custom tiling function adapts the built-in binary tiling function
        // for the appropriate aspect ratio when the treemap is zoomed-in.
        function tile(node, x0, y0, x1, y1) {
            d3.treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / width * (x1 - x0);
                child.x1 = x0 + child.x1 / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        // Compute the layout.
        const hierarchy = d3.hierarchy(adaptTracksToHierarchy(tracks))
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);
        const root = d3.treemap().tile(tile)(hierarchy);

        // Create the scales.
        const x = d3.scaleLinear().rangeRound([0, width]);
        const y = d3.scaleLinear().rangeRound([0, height]);

        // Formatting utilities.
        const format = d3.format(",d");
        const name = d => d.ancestors().reverse().map(d => d.data.name).join("/");

        // Create the SVG container.
        const svg = d3.select(gridRef.current)
            .attr("viewBox", [0.5, -30.5, width, height + 30])
            .attr("width", width)
            .attr("height", height + 30)
            .attr("style", "max-width: 100%; height: auto;")
            .style("font", "30px sans-serif");

        // Display the root.
        let group = svg.append("g")
            .call(render, root);

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children)
                .join("g");

            node.filter(d => d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => zoomin(d));

            node.filter(d => !d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => {
                    return handlePlayPause(d.data);
                });

            node.append("title")
                .text(d => `${name(d)}\n${format(d.value)}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = uid("leaf")).id)
                .attr("fill", "#131620")
                .attr("stroke", "#363941")
                .attr("rx", 8)
                .attr("ry", 8);

            node.append("clipPath")
                .attr("id", d => (d.clipUid = uid("clip")).id)
                .append("use")
                .attr("xlink:href", d => d.leafUid.href);

            node.append("text")
                .attr("x", 26)
                .attr("y", 56)
                .attr("font-family", "SpaceGrotesk, sans-serif")
                .attr("font-size", "36px")
                .attr("line-height", "36px")
                .attr("font-weight", "700")
                .attr("fill", "#fff")
                .text(d => d.data.logoText);

            node.append("text")
                .attr("x", 26)
                .attr("y", 92)
                .attr("font-family", "Roboto, sans-serif")
                .attr("font-size", "32px")
                .attr("font-weight", "400")
                .attr("line-height", "36px")
                .attr("fill", "rgba(255, 255, 255, 0.4)")
                .text(d => d.data.name);

            node.append("text")
                .attr("x", 26)
                .attr("y", 128)
                .attr("font-family", "SpaceGrotesk, sans-serif")
                .attr("font-size", "32px")
                .attr("font-weight", "700")
                .attr("line-height", "36px")
                .attr("fill", "#A93AFF")
                .text(d => `$${d.data.votes}`);

            group.call(position, root);
        }
        function position(group, root) {
            const padding = 4;

            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,-30)` : `translate(${x(d.x0) + padding},${y(d.y0) + padding})`)
                .select("rect")
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0) - padding * 2)
                .attr("height", d => d === root ? 30 : y(d.y1) - y(d.y0) - padding * 2);
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .attrTween("opacity", () => d3.interpolate(1, 0))
                    .call(position, d.parent))
                .call(t => group1.transition(t)
                    .attrTween("opacity", () => d3.interpolate(0, 1))
                    .call(position, d));
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .attrTween("opacity", () => d3.interpolate(1, 0))
                    .call(position, d))
                .call(t => group1.transition(t)
                    .call(position, d.parent));
        }
    };

    function uid(name) {
        return {
            id: `O-${name}-${Math.random().toString(16).slice(2)}`,
            href: `#O-${name}-${Math.random().toString(16).slice(2)}`
        };
    }

    return (
        <svg ref={gridRef} className="leaderboard-grid"></svg>
    );
};

export default LeaderBoardGrid;