import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlayFilled } from '../../assets/icons/play-filled.svg';
import { ReactComponent as StopFilled } from '../../assets/icons/stop-filled.svg';
import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg';

interface LeaderBoardRowProps {
    slug: string;
    trackUrl: string;
    title: string;
    reward: string;
    plays: number;
    votes: number;
    onVote?: () => void;
    onPlayPause?: () => void;
    isPlaying?: boolean;
    onProgress?: (progress: number) => void;
    volume?: number;
    isPlayer?: boolean;
    index: number;
    logoImage: string;
    logoText: string;
}

const LeaderBoardRow: React.FC<LeaderBoardRowProps> = ({ slug, trackUrl, title, reward, plays, votes, onVote, onPlayPause, isPlaying, onProgress, volume = 1, isPlayer = false, index, logoImage, logoText }) => {
    const navigate = useNavigate();
    const usdBalance = (votes / 100000).toFixed(2);

    const togglePlayPause = () => {
        if (onPlayPause) {
            onPlayPause();
        }
    };

    const handleLogoClick = () => {
        navigate(`/project/${slug}`);
    };

    return (
        <div className={`leaderboard-row ${isPlayer ? 'player' : ''} ${isPlaying ? 'playing' : ''}`}>
            <div className="leaderboard-index" onClick={togglePlayPause}>
                {isPlaying ? <StopFilled /> : <PlayFilled />}
                {!isPlayer && <div className="track-number">{index + 1}</div>}
            </div>
            {!isPlayer && (
                <div className="leaderboard-logo" onClick={handleLogoClick}>
                    <img src={logoImage} alt={logoText} />
                    <div>{logoText}</div>
                </div>
            )}
            <div className="leaderboard-info">
                <div className="leaderboard-title">{title}</div>
                {!isPlayer && <div className="leaderboard-reward">
                    {`${reward} PP per click, ${plays || 0} play${plays === 1 ? '' : 's'} left`}
                </div>}
            </div>
            <div className="leaderboard-vote" onClick={onVote}>
                <VoteIcon />
                {!isPlayer && <div className="leaderboard-votes">${usdBalance}</div>}
            </div>
        </div>
    );
};

export default LeaderBoardRow;