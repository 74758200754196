import React from 'react';
import BaseIcon from '../../../assets/icons/icon-base.svg';
import BitcoinIcon from '../../../assets/icons/icon-bitcoin.svg';
import SolanaIcon from '../../../assets/icons/icon-solana.svg';
import EthereumIcon from '../../../assets/icons/icon-ethereum.svg';
import PolygonIcon from '../../../assets/icons/icon-polygon.svg';
import ClipboardButton from '../ClipboardButton/ClipboardButton';
import TelegramText from '../Text/TelegramText';
import './CryptoInfo.scss';

const CryptoInfo = ({ wallets }) => {
    const icons = {
        ethereum: EthereumIcon,
        bitcoin: BitcoinIcon,
        polygon: PolygonIcon,
        solana: SolanaIcon,
        base: BaseIcon,
    };

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard:', text);
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    }

    return (
        <div className="crypto-info">
            {Object.keys(wallets).map((key) => (
                <div className="crypto-box" key={key}>
                    <div className="crypto-box__img">
                        <img src={icons[key]} alt={`${key}Icon`} />
                    </div>
                    <div className="crypto-box__text">
                        <TelegramText className={'telegramSubtitle'}>{key.charAt(0).toUpperCase() + key.slice(1)}</TelegramText>
                        <TelegramText className={'white-opacity'}>{wallets[key]}</TelegramText>
                    </div>
                    <div className="crypto-box__btn">
                        <ClipboardButton onClick={() => copyToClipboard(wallets[key])}></ClipboardButton>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CryptoInfo;