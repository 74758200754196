import axios from 'axios';
import { API_URL, BOT_ID, BRN_URL, MERCHANT_ID, TASK_ID, CATEGORY_ID } from "./Variables"
import { useTelegram } from "../../hooks/useTelegram";

// temp decigion for work with local storage without SSR
export const getProductMetadata = (projectName, tracks) => {
    let track = tracks.find(track => (track.name).toLowerCase() === projectName.toLowerCase());

    if (!track) {
        track = tracks[0];
    }

    const metadata = {
        url: track.url,
        title: track.title,
        reward: track.reward,
        plays: track.plays,
        votes: track.votes,
        logoImage: track.image,
        logoText: track.logo_Text,
        slug: track.slug,
    };

    return {
        status: true,
        data: [metadata]
    };
};

export const getAllTracks = async (customerId, token, categoryId = null) => {
    const resp = await getProducts(customerId, token, categoryId);
    return {
        status: true,
        data: resp.top.map((product, index) => ({
            ...product.attributes,
            id: product.product_id,
            reward: 20 - index,
            plays: product.attributes.plays || 2,
            votes: product.score || 0,
            slug: product.slug,
        })),
    };
};

export const uploadFileImage = async (data) => {
    let resp;
    await axios.post(
        `${BRN_URL}/attachment/image/${BOT_ID}`,
        data,
    )
        .then(response => {
            resp = response.data;
        })
        .catch(error => console.log('uploadFileImage error', error));
    return resp;
}

export const uploadFileAudio = async (data) => {
    let resp;
    await axios.post(
        `${BRN_URL}/attachment/audio/${BOT_ID}`,
        data,
    )
        .then(response => {
            resp = response.data;
            console.log('uploadFileAudio response:', resp);
        })
        .catch(error => console.log('uploadFileAudio error', error));
    return resp;
};

export const rateProductWithClaimedPoints = async (points, productId) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || '',
            'Content-Type': 'application/json'
        }
    };
    const body = {
        value: points
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/score-allocation/product/${productId}/contribute`, body, config)
        .then(response => {
            resp = response.data;
            console.log('Server response:', resp);
        })
        .catch(error => console.log('get error', error));
    return resp;
};

export const sendPointsToServer = async (points) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || '',
            'Content-Type': 'application/json'
        }
    };
    const body = {
        input: {
            value: points
        }
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${TASK_ID}/claim`, body, config)
        .then(response => {
            resp = response.data;
            console.log('Server response:', resp);
        })
        .catch(error => console.log('get error', error));
    return resp;
};

export const getCustomerParams = async (params, code) => {
    let resp;
    const body = {
        init_data: params,
        entity: "customer",
        referral_code: code
    }

    await axios
        .post(`${BRN_URL}/service/${BOT_ID}/telegram/webapp/user`, body)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getMerchantId = async (customerId, tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/get/merchant?ensure=true`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getAllCategories = async (customerId, tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/categories/${BOT_ID}`, config)
        .then(response => {
            resp = response.data;
        })
        .catch(error => console.log('get categories error', error));
    return resp
}

export const createProduct = async (formData) => {
    let resp;
    const body = {
        "bot_id": BOT_ID,
        "category_id": CATEGORY_ID,
        "name": formData.get('attributes[name]'),
        "attributes": {
            "price": 1,
            "image": formData.get('attributes[image]'),
            "name": formData.get('attributes[name]'),
            "token_url": formData.get('attributes[token_url]'),
            "project_url": formData.get('attributes[project_url]'),
            "logo_text": formData.get('attributes[logo_text]'),
            "url": formData.get('attributes[url]'),
        }
    };
    let config = {
        headers: {
            'X-Access-Token': localStorage.merchant_token || ''
        }
    };
    await axios
        .post(`${BRN_URL}/api/product`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getProducts = async (customerId, token, categoryId = null) => {
    let url = `${BRN_URL}/tool/${BOT_ID}/score-allocation/product/top?access_token=${token}`;
    if (categoryId) {
        url += `&category_id=${categoryId}`;
    }

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const registerInAffiliate = async (customerId, tokenCustomer) => {
    let resp;
    const body = {
        merchant_id: MERCHANT_ID,
        code: customerId,
    };
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/apply`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getLoyaltyData = async (customerId, tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    const merchantId = MERCHANT_ID;
    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/data?merchant_id=${merchantId}`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => {
            console.log('data.error', error.message)
            if (error.message === 'Request failed with status code 401') {
                localStorage.clear();
                const webApp = useTelegram();
                if (webApp?.showAlert) {
                    webApp.showAlert('Please clear the history of the chat and return.');
                }
                window.confirm('Please clear the history of the chat and return.');
            }
        });
    return resp
};
export const getCustomerToken = async (tokenCustomer) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/get/customer?ensure=true`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

function wait(ms) {
    return new Promise((resolve) => { setTimeout(resolve, ms) });
}

export const claimPoints = async (tokenCustomer, taskId) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };
    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${taskId}/claim`, {}, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const claimSeveralPoints = async (tokenCustomer, quantity) => {
    let resp;
    let config = {
        headers: {
            'X-Access-Token': tokenCustomer || ''
        }
    };

    const requests = Array.from(Array(quantity), (async (x) =>
        await axios.post(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${TASK_ID}/claim`, {}, config)));

    await axios.all(requests)
        .then(axios.spread((...responses) => {
            resp = responses.map(item => item.data);
            // console.log(resp);
        }))
        .catch(error => console.log('get error', error));
    return resp
};
export const getPointsLimits = async (tokenCustomer) => {
    let resp;

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-task/${TASK_ID}?access_token=${tokenCustomer}`)
        .then(response => {
            resp = response?.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getTgToken = async (userData) => {
    let resp;
    let config = {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    }
    let body = {
        initData: userData
    }
    await axios
        .post(`${API_URL}/customer`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getTasks = async () => {
    let resp;
    const token = localStorage.getItem('token');
    const merchantId = MERCHANT_ID;
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: merchantId
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/loyalty-tasks`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const setInteraction = async (link) => {
    let resp;
    const token = localStorage.getItem('token');
    const merchantId = localStorage.merchant_id || MERCHANT_ID;
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: merchantId
        },
        headers: {
            'X-Access-Token': token
        }
    }
    const body = {
        "type": "link-visited",
        "data": {
            "link": link
        }
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/interaction-tracker/track`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getEarnings = async () => {
    let resp;
    const token = localStorage.getItem('token');
    const merchantId = localStorage.merchant_id || MERCHANT_ID;
    let config = {
        params: {
            limit: 300,
            offset: 0,
            merchant_id: merchantId,
            status: 'pending_approvement',
            type: 'withdraw',
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/transactions`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getReferrals = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            limit: 500,
            offset: 0,
        },
        headers: {
            'X-Access-Token': token
        }
    }
    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/affiliate-program/referrals`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getGameReward = async (id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    let body = id ? {
        drop_id: id
    } : {};

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/click-game/click`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};

export const getNextBall = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/click-game/drop`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
};
export const getCollectibles = async (id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        params: {
            collection_id: id
        },
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/click-game/items`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch(error => {
            console.log('get error', error);
        });
    return resp
};

export const equipItem = async (id) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
        'item_id': id
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/click-game/equip`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch((e) => {
            console.log(e);
        });

    return resp
};
export const sellPaddle = async (id, quantity) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
        'item_id': id,
        "quantity": quantity
    }

    await axios
        .post(`${BRN_URL}/tool/${BOT_ID}/click-game/sell`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch((e) => {
            console.log(e);
        });

    return resp
};

export const getEquipment = async (id) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .get(`${BRN_URL}/tool/${BOT_ID}/click-game/equipment`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        }).catch((e) => {
            console.log(e);
        });

    return resp
};
export const setWalletAddress = async (walletAddress) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }
    let body = {
        address: walletAddress,
        blockchain: 'solana'
    }
    await axios
        .post(`${BRN_URL}/person/crypto-wallet`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        });
    return resp
};
export const deleteWalletAddress = async (walletAddress) => {
    let resp;
    const token = localStorage.getItem('token');

    let config = {
        headers: {
            'X-Access-Token': token
        }
    }

    await axios
        .delete(`${BRN_URL}/person/crypto-wallet`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
    return resp
};

//
export const getCustomersWallet = async () => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .get(`${BRN_URL}/person/me`, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}

export const changeCustomerData = async (body, id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .put(`${BRN_URL}/api/customer`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}
export const changeProfile = async (body, id) => {
    let resp;
    const token = localStorage.getItem('token');
    let config = {
        headers: {
            'X-Access-Token': token || ''
        }
    };
    await axios
        .put(`${BRN_URL}/person/me`, body, config)
        .then(response => {
            resp = response.data;
            // console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}
export const flySignup = async (body, id) => {
    let resp;
    await axios
        .post(`${BRN_URL}/customer/create/${BOT_ID}`, body)
        .then(response => {
            resp = response.data;
            console.log(resp);
        })
        .catch(error => console.log('get error', error));
    return resp
}
