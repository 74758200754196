import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { ReactComponent as ArrowIcon } from '../../assets/arrow-right-icon.svg';

import { ReactComponent as CryptoIcon } from '../../assets/close-circle-linear.svg';
import { deleteWalletAddress, getCustomersWallet, setWalletAddress } from "../../logic/server/api";
// import {PublicKey} from "@solana/web3.js";
import { useSprings } from "@react-spring/web";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import DecorImage from '../../assets/backgrounds/claim-decor.png';

const Claim = () => {

    const positions = [
        { top: '19px', left: '55px' },
        { top: '164px', left: '-57px' },
        { top: '65px', left: '314px' },
        { top: '328px', left: '216px' },
        // Add more positions as needed
    ];

    const [springs, api] = useSprings(
        positions.length,
        index => ({
            from: { transform: 'rotate(0deg)' },
            to: async (next) => {
                while (true) {
                    // await next({ transform: 'rotate(360deg)' });
                    // await next({ transform: 'rotate(0deg)' });
                    await next({ transform: 'rotate3d(0, 1, 0, 360deg)' });
                    await next({ transform: 'rotate3d(0, 1, 0, 0deg)' });
                }
            },
            config: { duration: 3000 }, // adjust the duration for the spin speed
        })
    );
    api.start();

    const [walletAddress, SetWalletAddress] = useState('');
    const [error, SetError] = useState(false);
    const [walletAddressSaved, SetWalletAddressSaved] = useState('');
    let count = useSelector(state => state.counter.count);
    count = count || localStorage.getItem('points');
    useEffect(() => {
        getWallet();
    }, []);

    async function getWallet() {
        const resp = await getCustomersWallet();
        if (resp?.person?.crypto_wallet?.address) {
            SetWalletAddress(resp?.person?.crypto_wallet?.address);
            SetWalletAddressSaved(resp?.person?.crypto_wallet?.address);
        }
        console.log(resp);
    }
    async function deleteWallet() {
        return await deleteWalletAddress(walletAddress);
    }
    async function addWallet(walletToSave) {
        return await setWalletAddress(walletToSave);
    }
    const handleChange = (event) => {
        SetError(false);
        // console.log(event.target.value);
        SetWalletAddress(event.target.value);
        if (event.target.value.length >= 30) {
            setWallet(event.target.value);
        }
    };
    const clearWallet = (event) => {
        if (SetWalletAddressSaved) {
            deleteWallet().then(res => {
                console.log(res);
                SetWalletAddress('');
                SetWalletAddressSaved('');
            }).catch(err => {
                SetError(true);
            });
        } else {
            SetWalletAddress('');
            SetWalletAddressSaved('');
        }
    };
    function setWallet(address) {
        const walletToSave = address;
        if (walletToSave) {
            try {
                // const publicKey = new PublicKey(walletToSave);
                //
                // console.log(PublicKey.isOnCurve(publicKey.toBytes()))
                if (walletAddressSaved) {
                    deleteWallet().then(() => {
                        addWallet(walletToSave).then(() => {
                            SetWalletAddressSaved(walletToSave);
                            toast.success('Wallet address saved successfully!');
                        }).catch(err => {
                            SetWalletAddressSaved('');
                            SetError(true);
                            toast.error('Failed to save wallet address!');
                        });
                    }).catch(err => {
                        SetWalletAddressSaved('');
                        SetError(true);
                        toast.error('Failed to delete existing wallet address!');
                    });
                } else {
                    addWallet(walletToSave).then(() => {
                        SetWalletAddressSaved(walletToSave);
                        toast.success('Wallet address saved successfully!');
                    }).catch(err => {
                        // SetWalletAddressSaved('');
                        SetError(true);
                        toast.error('Failed to save wallet address!');
                    });
                }
            } catch (err) {
                toast.error('Invalid wallet address format!');
                return false;
            }
        }
    };

    const navigateToWeb = () => {
        console.log('redeem');
    }

    return (
        <TelegramScreen
            className={``} id='gesuredZone'>
            <div className='main-container'>
                <div style={{ position: 'relative' }}>
                    <div className='payment-container'>
                        <div className="payment-container__page">
                            <div className="payment-block">
                                <TelegramText className={'payment-block__title'}>1000</TelegramText>
                                <TelegramText className={'payment-block__subtitle'}>1 PowerPoint = 0.01 USD</TelegramText>
                                <div className="btns-holder">
                                    <a href="#redeem-cards-container">
                                        <TelegramButton>Connect wallet</TelegramButton>
                                    </a>
                                    <TelegramButton className={'outlined'}>Pay 10 USD</TelegramButton>
                                </div>
                            </div>
                        </div>
                        <div className="payment-container__page">
                            <div id="redeem-cards-container" className={'redeem-cards-container gap-of-wrapper-of-ppurs'}>
                                <div className='wrapper-for-crypto-wallet'>
                                    <div>
                                        <p className={'text-name-roboto-20'}>Redeem tokens</p>
                                        <p className={'bold-blue-text-name-roboto'}>Connected wallet (Solana)</p>
                                    </div>
                                    <form className='form-for-crypto-wallet'>
                                        <label></label>
                                        <input
                                            type="text"
                                            className={`input-for-crypto-wallet ${error ? 'input-error-text' : ''}`}
                                            placeholder="0xdafea34..."
                                            onChange={handleChange}
                                            value={walletAddress}
                                            required
                                        />
                                        <ArrowIcon className={`set-wallet ${walletAddress ? 'hide-button' : 'show-button'}`} onClick={setWallet}></ArrowIcon>
                                        <CryptoIcon className={`crypto-icon ${walletAddress ? 'show-button' : 'hide-button'}`} width="24" height="24"
                                            onClick={clearWallet} />
                                    </form>
                                    <div className={`cards__redeem-button  ${walletAddressSaved ? '' : 'disabled'}`} onClick={navigateToWeb}>
                                        Redeem now
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fixed-decor">
                            <img src={DecorImage} alt={'Welcome'} />
                        </div>
                        <div className='forms__bottom'>
                            <MainNavigation currentPage={'redeem'} />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}

                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default Claim;
