import { getTasks } from '../../../logic/server/api';
import { TaskType } from '../../../screens/tasks/TasksList';
import { setInteraction, claimPoints } from '../../../logic/server/api';
import { toast } from 'react-toastify';

export const handleInteraction = async (task, webApp, setLinkOpened, setTaskClaimed) => {
    if (task?.conditions[0]?.type === TaskType.referral) {
        window.location.assign('/earned');
        return;
    }
    if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed')) {
        const res = await setInteraction(task?.conditions[0].data?.link);
        webApp.openLink(task?.conditions[0].data?.link);
        if (res?.status) {
            setLinkOpened(prev => [...prev, { id: task.id, status: true }]);
        }
    } else if (task?.completion.claimable) {
        handleClaim(task, setTaskClaimed);
        toast.success(`You claimed ${task?.reward?.data?.value} coins successfully!`);
    }
};

export const handleClaim = async (task, setTaskClaimed) => {
    const res = await claimPoints(localStorage.getItem('token'), task.id);
    if (res?.status) {
        setTaskClaimed(prev => [...prev, { id: task.id, status: true }]);
    }
};

export const fetchTasks = async () => {
    try {
        const response = await getTasks();
        if (response && response.status) {
            return response.tasks;
        }
    } catch (error) {
        console.error('Error fetching tasks:', error);
    }
    return [];
};