import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from "../../hooks/useTelegram";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import P5Sketch from '../../components/p5-sketch';
import CryptoInfo from '../../components/kit/CryptoInfo/CryptoInfo';
import TasksList from '../../screens/tasks/TasksList';
import { fetchTasks, handleInteraction, handleClaim } from '../../components/app/utils/tasks.utils';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import iconX from '../../assets/icons/icon-x.svg';
import iconWeb from '../../assets/icons/icon-web.svg';
import iconOpener from '../../assets/icons/icon-opener.svg';
import iconOpenerClosed from '../../assets/icons/icon-opener-closed.svg';

const AnimationScreen = React.memo(({ collectibles, person, personPoints, setPersonPoints, tracks, handleTrackChange, detailsPage }) => {
    const [isGreen, setIsGreen] = useState(false);
    const [greenDuration, setGreenDuration] = useState(100000);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);
    const greenTimeoutRef = useRef(null);
    const [tasks, setTasks] = useState([]);
    const [isPromotionOpen, setIsPromotionOpen] = useState(true);
    const [isMarketingOpen, setIsMarketingOpen] = useState(true);
    const navigate = useNavigate();
    const { webApp } = useTelegram();
    const currentWallets = tracks[0]?.wallets || {};

    const navigateToWeb = (url) => {
        window.open(url, '_blank')
    }

    useEffect(() => {
        const loadTasks = async () => {
            const tasksData = await fetchTasks();
            setTasks(tasksData);
        };

        loadTasks();
    }, []);

    useEffect(() => {
        if (isGreen) {
            if (greenTimeoutRef.current) {
                clearTimeout(greenTimeoutRef.current);
            }
            greenTimeoutRef.current = setTimeout(() => {
                setIsGreen(false);
            }, greenDuration);
        }
        return () => clearTimeout(greenTimeoutRef.current);
    }, [isGreen, greenDuration]);

    return (
        <TelegramScreen className={`game`} id="gesuredZone">
            {detailsPage && (
                <header className="details-header">
                    <button onClick={() => navigate('/charts')} className="back-button">
                        <img src={arrowLeft} alt="Back" className="icon" />
                        Top Chart
                    </button>
                    <div className="social-icons">
                        <button onClick={() => navigateToWeb(tracks[0].token_url)} className="back-button">
                            <img src={iconX} alt="X" className="icon" />
                        </button>
                        <button onClick={() => navigateToWeb(tracks[0].project_url)} className="back-button">
                            <img src={iconWeb} alt="Web" className="icon" />
                        </button>
                    </div>
                </header>
            )}
            <div className='main-container'>
                <div className='sketch'>
                    <P5Sketch personPoints={personPoints} setPersonPoints={setPersonPoints} isGreen={isGreen} setIsGreen={setIsGreen} tracksList={tracks} onTrackChange={handleTrackChange} />
                </div>
                {detailsPage && (
                    <div className="details-sections">
                        <div className={`details-section ${!isPromotionOpen ? 'closed' : ''}`}>
                            <div className="section-header" onClick={() => setIsPromotionOpen(!isPromotionOpen)}>
                                <span className="section-title">Promotion wallets</span>
                                <span className="hide-icon">
                                    {isPromotionOpen ? 'HIDE' : 'SHOW'} <img src={isPromotionOpen ? iconOpener : iconOpenerClosed} alt="Toggle" />
                                </span>
                            </div>
                            {isPromotionOpen && <CryptoInfo wallets={currentWallets} />}
                        </div>
                        <div className={`details-section ${!isMarketingOpen ? 'closed' : ''}`}>
                            <div className="section-header" onClick={() => setIsMarketingOpen(!isMarketingOpen)}>
                                <span className="section-title">Marketing tasks</span>
                                <span className="hide-icon">
                                    {isMarketingOpen ? 'HIDE' : 'SHOW'} <img src={isMarketingOpen ? iconOpener : iconOpenerClosed} alt="Toggle" />
                                </span>
                            </div>
                            {isMarketingOpen && <TasksList tasks={tasks} handleInteraction={(task) => handleInteraction(task, webApp, setLinkOpened, setTaskClaimed)} />}
                        </div>
                    </div>
                )}
                <MainNavigation currentPage={'main'} />
            </div>
        </TelegramScreen>
    );
});

export default AnimationScreen;