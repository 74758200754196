import React from 'react';
import { ReactComponent as PPIcon } from "../../assets/icons/pp-small.svg";
import { ReactComponent as ClaimIcon } from "../../assets/icons/claim.svg";
import { ReactComponent as CheckIcon } from "../../assets/check-circle.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrow-right.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";
import { ReactComponent as FollowIcon } from "../../assets/icons/follow.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/invite.svg";

export const TaskType = {
    visited: 'interaction-tracker.link-visited',
    referral: 'referral-number',
    click: 'click',
};

export const TasksList = ({ tasks, handleInteraction }) => {
    return (
        <div className="tasks-list">
            {tasks?.filter(task => task.description !== 'Click').map((task) => (
                <div key={task.id} className={`new-card__task ${task?.completion.status === 'completed' ? 'disabled--bright' : ''}`} onClick={() => handleInteraction(task)}>
                    <div className={'card__column-to-row'}>
                        <div className={'card__task--text'}>
                            <div className="card__task--icon">
                                {task?.conditions[0]?.type === TaskType.visited && <FollowIcon />}
                                {task?.conditions[0]?.type === TaskType.referral && <InviteIcon />}
                                {task?.conditions[0]?.type === TaskType.click && <YoutubeIcon />}
                            </div>
                            <div className="card__task--holder">
                                {task?.title}
                                <p className={'small-card-task-text'}><PPIcon /> {task?.reward?.data?.value} PP</p>
                            </div>
                        </div>
                        {(!task?.completion.claimable && task?.completion.status !== 'completed') && (
                            <div className={'claim-row'}>
                                <p className={'small-card-task-text'}>APPLY</p>
                                <div className="small-card-task-icon">
                                    <ArrowIcon />
                                </div>
                            </div>
                        )}
                        {(task?.completion.status === 'completed') && (
                            <div className={'claim-row'}>
                                <p className={'small-card-task-text'}>CLAIMED</p>
                                <div className="small-card-task-icon">
                                    <CheckIcon />
                                </div>
                            </div>
                        )}
                        {task?.completion.claimable && (
                            <div className={'claim-row'}>
                                <p className={'small-card-task-text'}>CLAIM</p>
                                <div className="small-card-task-icon">
                                    <ClaimIcon />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TasksList;